import React from 'react'
import styled from 'styled-components'
import { useStaticQuery, graphql } from "gatsby"
import Img from "gatsby-image"

const TargetWrapper = styled.section`
  width: 100%;
`;

const InnerWrapper = styled.div`
  width: 100%;
  padding: 3rem 0;

  ${({theme}) => theme.media.tablet} {
    padding: 3rem 0 0;
    margin-bottom: -9rem;
  }
  ${({theme}) => theme.media.laptop} {
    padding: 3rem 0 0;
    margin-bottom: -6rem;
  }
  ${({theme}) => theme.media.desktop} {
    padding: 6rem 0;
  }
`;

const BoxImage = styled.div`
  position: relative;
  width: 100%;

  ::before {
    position: absolute;
    content: '';
    top: -2rem;
    right: 0;
    height: 2rem;
    width: 60%;
    background: ${({theme}) => theme.colors.mainBlack};

    ${({theme}) => theme.media.desktop} {
      width: 50%;
    }
  }

  ${({theme}) => theme.media.laptop} {
    height: 55vh;
  }

  ${({theme}) => theme.media.desktop} {
    height: 35vh;

    img {
      height: 55vh !important;
    }
  }
`;

const BoxText = styled.div`
  width: 100%;
  padding: 2rem;
  background: ${({theme}) => theme.colors.mainBlack};

  ${({theme}) => theme.media.tablet} {
    width: 60%;
    margin: 0 0 0 auto;
    transform: translatey(-30%);
  }
  ${({theme}) => theme.media.tablet} {
    padding: 4rem;
  }
  ${({theme}) => theme.media.laptop} {
    padding: 4rem 11rem 4rem 6rem;
  }
  ${({theme}) => theme.media.desktop} {
    width: 50%;
    transform: translatey(-10%);
    padding: 6rem 11rem 6rem 6rem;
  }

  ${({theme}) => theme.media.desktopXL} {
    width: 45%;
    padding: 6rem 15rem 6rem 6rem;
  }
`;

const H3 = styled.h3`
  font-size: calc(2.27273vw + 14.72727px);
  margin-bottom: 1rem;
  line-height: 1.2;
  font-weight: 700;
  color: ${({theme}) => theme.colors.mainWhite};

  ${({theme}) => theme.media.laptop} {
    font-size: 1.9rem;
  }

  ${({theme}) => theme.media.desktop} {
    font-size: 2.5rem;
  }

  span {
    font-size: calc(2.27273vw + 15.72727px);
    color: ${({theme}) => theme.colors.mainYellow};
  }
`;

const Text = styled.p`
  margin: 1rem 0;
  color: ${({theme}) => theme.colors.mainWhite};

  ${({theme}) => theme.media.laptopXL} {
    padding: 0 4rem 0 0;
  }

  ${({theme}) => theme.media.desktop} {
    font-size: 1.4rem;
  }
`;

const UlList = styled.ul`
  color: ${({theme}) => theme.colors.mainWhite};
  list-style: none;
  font-weight: 300;
`;

const LiItem = styled.li`
  margin: .6rem 0;

  :first-child span {
    padding: 0 .4rem;
  }

  span {
    padding: 0 .3rem;
    margin-right: .5rem;
    font-weight: 600;
    color: ${({theme}) => theme.colors.mainBlack};
    background: ${({theme}) => theme.colors.mainYellow};

    ${({theme}) => theme.media.desktop} {
      margin-right: .7rem;
    }
  }

  ${({theme}) => theme.media.desktop} {
    font-size: 1.3rem;
  }
`;


const Target = () => {
  const data = useStaticQuery(graphql`
    query {
      targetImg: file(relativePath: { eq: "target.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 1920) {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
  `)

  return (
    <TargetWrapper>
      <InnerWrapper>

        <BoxImage>
          <Img fluid={data.targetImg.childImageSharp.fluid} />
        </BoxImage>

        <BoxText>
          <H3 data-sal="fade" data-sal="slide-up" data-sal-easing="ease" data-sal-duration="900">Dla kogo jest komputer w chmurze<span>?</span></H3>
          <Text data-sal="fade" data-sal="slide-up" data-sal-easing="ease" data-sal-duration="1000">Zastanawiasz się czy usługa zdalnego pulpitu jest dla Ciebie? Nasza usługa dedykowana jest szczególnie dla:</Text>
          <UlList data-sal="fade" data-sal="slide-up" data-sal-easing="ease" data-sal-duration="1100">
            <LiItem><span>1</span>biur rachunkowych</LiItem>
            <LiItem><span>2</span>handlowców</LiItem>
            <LiItem><span>3</span>zakładów produkcyjnych</LiItem>
            <LiItem><span>4</span>firm wielodziałowych</LiItem>
            <LiItem><span>5</span>firm dystrybucyjnych</LiItem>
            <LiItem><span>6</span>i wielu innych branży w, których potrzebna jest praca zdalna</LiItem>
          </UlList>
        </BoxText>

      </InnerWrapper>
    </TargetWrapper>
  )
}

export default Target