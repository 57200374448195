import React from 'react'
import styled from 'styled-components'
import { useStaticQuery, graphql } from "gatsby"

const Box = styled.div`
  position: relative;
  padding: 2rem;
  margin: 1.5rem 0;
  border: 5px solid ${({theme}) => theme.colors.mainBlue};
  box-shadow: 4px 4px 0px 0px rgba(232,222,46,1), inset 4px 4px 0px 0px rgba(232,222,46,1);

  ${({theme}) => theme.media.tablet} {
    margin: 0;
  }

  ${({theme}) => theme.media.laptop} {
    padding: 3rem 2rem;
  }

  ${({theme}) => theme.media.laptopXL} {
    border: 7px solid ${({theme}) => theme.colors.mainBlue};
  }

  ${({theme}) => theme.media.desktop} {
    padding: 5rem 2.5rem;
  }

  span {
    position: absolute;
    top: 0;
    right: .5rem;
    color: ${({theme}) => theme.colors.mainBlue};
    font-size: 6rem;
    line-height: 1;
    opacity: .2;
  }
`;

const H3 = styled.h3`
  font-size: 1.5rem;

  ${({theme}) => theme.media.laptopXL} {
    font-size: 1.7rem;
    margin-bottom: 1.5rem;
  }

  ${({theme}) => theme.media.desktop} {
    font-size: 1.8rem;
    margin-bottom: 2.5rem;
  }
`;

const Text = styled.p`
  margin: 1rem 0;
  color: ${({theme}) => theme.colors.mainBlack};

  ${({theme}) => theme.media.laptop} {
    font-size: 1.2rem;
  }

  ${({theme}) => theme.media.desktop} {
    font-size: 1.4rem;
  }
`;


const OneAsset = () => {
  const data = useStaticQuery(graphql`
    query SiteTitleQueryAssets {
      site {
        siteMetadata {
          assets {
            number
            text
            title
            duration
          }
        }
      }
    }
  `)

  return (
    <>
      {data.site.siteMetadata.assets.map(asset => (
        <Box data-sal="fade" data-sal="slide-up" data-sal-easing="ease" data-sal-duration={asset.duration} key={asset.number}>
          <span>{asset.number}</span>
          <H3>{asset.title}</H3>
          <Text>{asset.text}</Text>
      </Box>
      ))}
    </>
  )
}

export default OneAsset