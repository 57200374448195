import React, { useRef, useEffect } from 'react'
import gsap from 'gsap';
import styled from 'styled-components'

import ImageF from '../../images/facebook-f-brands.svg'
import ImageP from '../../images/mobile-alt-solid.svg'
import ImageL from '../../images/linkedin-in-brands.svg'

const WrapperStrip = styled.div`
  width: 100%;
  padding: 1rem 2rem;
  background: ${({theme}) => theme.colors.mainBlue};
  display: flex;
  justify-content: flex-end;

  ${({theme}) => theme.media.tablet} {
    padding: 1rem 5rem;
  }

  ${({theme}) => theme.media.laptop} {
    padding: 1rem 15rem;
  }

  ${({theme}) => theme.media.desktop} {
    padding: 1rem 25rem;
  }

  ${({theme}) => theme.media.desktopXL} {
    padding: 1.3rem 45rem;
  }
`;

const Button = styled.button`
  display: inline-block;
  display: flex;
  padding: .7rem 1.2rem;
  border: 2px solid ${({theme}) => theme.colors.mainWhite};
  margin: 0 .5rem;
  box-shadow: 3px 3px 0 0 ${({theme}) => theme.colors.mainYellow};

  svg {
    width: 1rem;
    color: ${({theme}) => theme.colors.mainWhite};
  }

  ${({theme}) => theme.media.laptop} {
    display: ${({isShow}) => isShow ? "none" : ""};
  }

  ${({theme}) => theme.media.desktop} {
    margin: .5rem;
  }
`;


const HeaderStrip = () => {

  const blueWrapper = useRef(null);

  useEffect(() => {

    const element = blueWrapper.current;

    gsap.set(element, {autoAlpha: 0, x: '-10%'});
  
    const tlBlue = gsap.timeline();
  
    tlBlue.to(element, {duration: .8, autoAlpha: 1, x: 0}, 0.6)
  }, []);

  return (
    <WrapperStrip ref={blueWrapper}>
      <Button as="a" href="https://www.facebook.com/ePulpit24" target="_blank">
        <ImageF />
      </Button>
      <Button as="a" href="https://www.linkedin.com/company/18892638/" target="_blank">
        <ImageL />
      </Button>
      <Button as="a" href="tel:+48525222242" isShow="isShow">
        <ImageP />
      </Button>
    </WrapperStrip>
  )
}

export default HeaderStrip;