import React from 'react'
import styled from 'styled-components'
import OneAsset from './oneAsset'

const AssetsWrapper = styled.section`
  width: 100%;
  padding: 4rem 2rem;

  ${({theme}) => theme.media.laptopXL} {
    padding: 6rem 3rem;
  }

  ${({theme}) => theme.media.desktop} {
    padding: 8rem 3rem;
  }
`;

const InnerWrapper = styled.div`
  width: 100%;

  ${({theme}) => theme.media.tabletXL} {
    max-width: 90%;
    margin: 0 auto;
  }

  ${({theme}) => theme.media.desktop} {
    max-width: 1400px;
  }
`;

const WrapperText = styled.div`
  width: 100%;

  ${({theme}) => theme.media.tablet} {
    width: 65%;
  }

  ${({theme}) => theme.media.laptop} {
    width: 55%;
  }
`;

const H2 = styled.h2`
  color: ${({theme}) => theme.colors.mainBlack};
  font-size: calc(3.18182vw + 21.81818px);
  line-height: 1.3;
  margin-bottom: 1rem;

  ${({theme}) => theme.media.tablet} {
    font-size: 2rem;
  }

  ${({theme}) => theme.media.laptop} {
    font-size: 3rem;
  }
`;

const TextTop = styled.p`
  margin: 1rem 0;
  color: ${({theme}) => theme.colors.mainBlack};

  ${({theme}) => theme.media.desktop} {
    font-size: 1.4rem;
  }
`;

const Boxes = styled.div`
  margin-top: 1.5rem;
  display: flex;
  flex-direction: column;

  ${({theme}) => theme.media.tablet} {
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-gap: 2rem;
  }

  ${({theme}) => theme.media.laptop} {
    grid-template-columns: 1fr 1fr 1fr 1fr;
    margin-top: 3rem;
  }
`;


const Assets = () => {
  return (
    <AssetsWrapper>
      <InnerWrapper>

        <WrapperText>
          <H2>Zalety komputera w chmurze.</H2>
          <TextTop>Umożliwia Ci pracę z dowolnego urządzenia w każdym miejscu na  świecie! Twój cały komputer z Twoimi wszystkimi aplikacjami oraz programami, które są dostępne po zalogowaniu z dowolnej lokalizacji. Komputer w chmurze jest w pełni zarządzany przez nas, Ty potrzebujesz jedynie dostępu do Internetu.</TextTop>
        </WrapperText>
        <Boxes>
          <OneAsset />
        </Boxes>

      </InnerWrapper>
    </AssetsWrapper>
  )
}

export default Assets