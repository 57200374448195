import React from 'react'
import styled from 'styled-components'

import ShortMovie from '../../video/komputer-w-chmurze.mp4'

const MovieWrapper = styled.section`
  width: 100%;
  padding: 2rem 0 3rem;

  ${({theme}) => theme.media.tabletXL} {
    padding: 4rem 0 6rem;
  }

  ${({theme}) => theme.media.laptopXL} {
    padding: 4rem 0 8rem;
  }
`;

const InnerWrapper = styled.div`
  position: relative;
  width: 100%;

  ::before {
    position: absolute;
    content: '';
    top: -2rem;
    left: 0;
    height: 2rem;
    width: 60%;
    background: ${({theme}) => theme.colors.mainYellow};

    ${({theme}) => theme.media.tablet} {
      top: -1rem;
      width: 55%;
      height: 3rem;
    }
    ${({theme}) => theme.media.tabletXL} {
      top: -2rem;
      width: 55%;
      height: 4.5rem;
    }
    ${({theme}) => theme.media.laptop} {
      top: -2rem;
      width: 100%;
      height: 4.5rem;
    }
  }

  ::after {
    position: absolute;
    content: '';
    bottom: -2rem;
    left: 0;
    height: 2rem;
    width: 60%;
    background: ${({theme}) => theme.colors.mainYellow};

    ${({theme}) => theme.media.tablet} {
      bottom: -2.5rem;
      left: 55%;
      width: 45%;
      height: 4.5rem;
    }
    ${({theme}) => theme.media.laptopXL} {
      bottom: -3.5rem;
      left: 55%;
      width: 45%;
      height: 3.5rem;
    }
  }

  ${({theme}) => theme.media.tablet} {
    display: grid;
    grid-template-columns: 55% 1fr;
  }

`;

const VideoWrapper = styled.div`
  width: 100%;
  padding: 2rem 0;
  background: ${({theme}) => theme.colors.mainBlack};

  video {
    width: 100%;
  }

  ${({theme}) => theme.media.tablet} {
    display: flex;
  }

  ${({theme}) => theme.media.desktop} {
    padding: 2rem;
  }
`;

const TextBox = styled.div`
  position: relative;
  padding: 2rem;
  background: ${({theme}) => theme.colors.mainBlue};

  ${({theme}) => theme.media.tabletXL} {
    padding: 2rem 3rem;
  }
  ${({theme}) => theme.media.laptop} {
    padding: 4rem 5rem;
    z-index: 2;
  }
  ${({theme}) => theme.media.laptopXL} {
    padding: 4rem 12rem 4rem 5rem;
  }
  ${({theme}) => theme.media.desktop} {
    padding: 4rem 15em 4rem 5rem;

    ::after {
      position: absolute;
      content: '';
      top: 0;
      right: 0;
      height: 100%;
      width: 5rem;
      background: ${({theme}) => theme.colors.mainYellow};
    }
  }

  ${({theme}) => theme.media.desktopXL} {
    padding: 5rem 20em 5rem 5rem;
  }
`;

const H2 = styled.h2`
  color: ${({theme}) => theme.colors.mainWhite};
  font-size: calc(3.18182vw + 21.81818px);
  line-height: 1.3;
  margin-bottom: 1rem;


  ${({theme}) => theme.media.tablet} {
    font-size: 1.6rem;
    margin-bottom: 1.6rem;
  }

  ${({theme}) => theme.media.laptop} {
    font-size: 2.2rem;
  }

  ${({theme}) => theme.media.desktop} {
    font-size: 2.7rem;
  }

  span {
    color: ${({theme}) => theme.colors.mainBlue};
  }
`;

const Text = styled.p`
  margin: 1rem 0;
  color: ${({theme}) => theme.colors.mainWhite};

  ${({theme}) => theme.media.desktop} {
    font-size: 1.4rem;
  }
`;


const Movie = () => {
 
  return (
    <MovieWrapper>
      <InnerWrapper>

        <VideoWrapper>
          <video autoPlay loop muted>
            <source src={ShortMovie} type="video/mp4"></source>
          </video>
        </VideoWrapper>

        <TextBox>
          <H2 data-sal="fade" data-sal="slide-up" data-sal-easing="ease" data-sal-duration="700">Prosta obsługa komputera w chmurze</H2>
          <Text data-sal="fade" data-sal="slide-up" data-sal-easing="ease" data-sal-duration="900">Pulpit zdalny jest rozwiązaniem, które umożliwia Ci pracę z dowolnej lokalizacji, jak? To bardzo proste!</Text>
          <Text data-sal="fade" data-sal="slide-up" data-sal-easing="ease" data-sal-duration="1000">Po zalogowaniu się do usługi pulpitu zdalnego otrzymujesz dostęp do swojego wirtualnego komputera ze wszystkimi Twoimi aplikacjami (typu INSERT, COMARCH) oraz innymi programami, umieszczonymi na serwerze chmury obliczeniowej. Twój komputer w biurze nie musi być uruchomiony - wszystkie Twoje aplikacje działają na serwerze w zabezpieczonej serwerowni! Dzięki temu, Ty możesz pracować z dowolnego urządzenia - laptopa, domowego komputera stacjonarnego, tabletu czy telefonu!</Text>
        </TextBox>

      </InnerWrapper>
    </MovieWrapper>
  )
}

export default Movie