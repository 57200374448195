import React from 'react'
import styled from 'styled-components'
import { useStaticQuery, graphql } from "gatsby"
import AniLink from "gatsby-plugin-transition-link/AniLink";
import Img from "gatsby-image"

const ClientsWrapper = styled.section`
  width: 100%;
  
  ${({theme}) => theme.media.desktopXL} {
    padding: 3rem 0;
  }
`;

const InnerWrapper = styled.div`
  position: relative;
  z-index: 1;
  width: 100%;
  padding: 2rem 2rem 3rem;

  ::after {
    position: absolute;
    z-index: -1;
    content: '';
    bottom: 0;
    left: 0;
    height: 50%;
    width: 100%;
    background: rgba(232,222,46, .2);
  }

  ${({theme}) => theme.media.desktopXL} {
    padding: 2rem 10rem 3rem;
  }
`;

const BoxText = styled.div`
  width: 100%;

  ${({theme}) => theme.media.tablet} {
    padding: 0 2rem;
  }

  ${({theme}) => theme.media.desktop} {
    padding: 0 5rem;
  }
`;

const H2 = styled.h2`
  color: ${({theme}) => theme.colors.mainBlack};
  font-size: calc(3.18182vw + 21.81818px);
  line-height: 1.3;
  margin-bottom: 1rem;


  ${({theme}) => theme.media.tablet} {
    font-size: 1.6rem;
    margin-bottom: 1.6rem;
  }
  ${({theme}) => theme.media.tabletXL} {
    font-size: 1.9rem;
    margin-bottom: 1.6rem;
  }

  ${({theme}) => theme.media.laptop} {
    font-size: 2.2rem;
  }

  ${({theme}) => theme.media.desktop} {
    font-size: 2.7rem;
  }

  span {
    color: ${({theme}) => theme.colors.mainBlue};
  }
`;

const BoxLogos = styled.div`
  width: 100%;
  padding: 1rem;
  display: grid;
  grid-gap: 1rem;
  grid-template-columns: 1fr 1fr;
  justify-content: center;
  align-items: center;
  background: ${({theme}) => theme.colors.mainWhite};

  ${({theme}) => theme.media.tablet} {
    grid-template-columns: repeat(4, 1fr);
    grid-gap: 2rem;
    padding: 2rem;
  }

  ${({theme}) => theme.media.tabletXL} {
    width: 80%;
  }

  ${({theme}) => theme.media.desktop} {
    padding: 2rem 2rem 2rem 5rem;
  }
`;

const BoxLogo = styled.div`
  width: 100%

  img {
    padding: .4rem;
  }

  ${({theme}) => theme.media.laptop} {
    width: 70%;
  }
  ${({theme}) => theme.media.desktop} {
    width: 50%;
  }
`;

const ActionBox = styled.div`
  width: 100%;
  padding: 1rem 2rem;
  background: ${({theme}) => theme.colors.mainBlue};
  text-align: center;
  margin-bottom: 3rem;
`;

const Text = styled.p`
  margin: 1rem 0;
  color: ${({theme}) => theme.colors.mainBlack};

  span {
    position: relative;
    font-weight: 700;

    ::after {
      position: absolute;
      content: '';
      bottom: -4px;
      left: 25%;
      height: 3px;
      width: 50%;
      background: ${({theme}) => theme.colors.mainWhite};
    }

    a {
      color: ${({theme}) => theme.colors.mainBlack};
      text-decoration: none;
    }
  }

  ${({theme}) => theme.media.laptop} {
    font-size: 1.2rem;

    span {
      font-size: 1.3rem;
    }
  }

  ${({theme}) => theme.media.desktop} {
    font-size: 1.4rem;

    span {
      font-size: 1.5rem;
    }
  }
`;


const Clients = () => {
  const data = useStaticQuery(graphql`
    query {
      bydgoszczLogo: file(relativePath: { eq: "loga-partners/bydgoszcz.png" }) {
        childImageSharp {
          fluid(maxWidth: 250) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      lazienkiLogo: file(relativePath: { eq: "loga-partners/era-lazienki.png" }) {
        childImageSharp {
          fluid(maxWidth: 250) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      expertfloatLogo: file(relativePath: { eq: "loga-partners/expertfloat.png" }) {
        childImageSharp {
          fluid(maxWidth: 250) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      orlenLogo: file(relativePath: { eq: "loga-partners/orlen.png" }) {
        childImageSharp {
          fluid(maxWidth: 250) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      techdataLogo: file(relativePath: { eq: "loga-partners/techdata.png" }) {
        childImageSharp {
          fluid(maxWidth: 250) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      tostaLogo: file(relativePath: { eq: "loga-partners/tosta.png" }) {
        childImageSharp {
          fluid(maxWidth: 250) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      wsgLogo: file(relativePath: { eq: "loga-partners/wsg_logo.png" }) {
        childImageSharp {
          fluid(maxWidth: 250) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      zdzLogo: file(relativePath: { eq: "loga-partners/ZDZ-logo.png" }) {
        childImageSharp {
          fluid(maxWidth: 250) {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
  `)


  return (
    <ClientsWrapper>
      <InnerWrapper>
        <BoxText>
          <H2>Zaufali <span>nam</span></H2>
        </BoxText>

        <BoxLogos>
          <BoxLogo>
            <Img fluid={data.bydgoszczLogo.childImageSharp.fluid} alt="logo Aktywna Bydgoszcz" />
          </BoxLogo>

          <BoxLogo>
            <Img fluid={data.lazienkiLogo.childImageSharp.fluid} alt="logo Era Łazienki"/>
          </BoxLogo>

          <BoxLogo>
            <Img fluid={data.expertfloatLogo.childImageSharp.fluid} alt="logo Expert Float"/>
          </BoxLogo>

          <BoxLogo>
            <Img fluid={data.techdataLogo.childImageSharp.fluid} alt="logo TechData"/>
          </BoxLogo>

          <BoxLogo>
            <Img fluid={data.orlenLogo.childImageSharp.fluid} alt="logo Orlen OceanLava"/>
          </BoxLogo>

          <BoxLogo>
            <Img fluid={data.tostaLogo.childImageSharp.fluid} alt="logo Tosta"/>
          </BoxLogo>

          <BoxLogo>
            <Img fluid={data.wsgLogo.childImageSharp.fluid} alt="logo WSG"/>
          </BoxLogo>

          <BoxLogo>
            <Img fluid={data.zdzLogo.childImageSharp.fluid} alt="logo ZDZ"/>
          </BoxLogo>

        </BoxLogos>

      </InnerWrapper>
      <ActionBox>
          <Text>Tobie również z chęcią pomożemy. <span><AniLink fade to="/kontakt">Porozmawiajmy</AniLink></span></Text>
      </ActionBox>
    </ClientsWrapper>
  )
}

export default Clients