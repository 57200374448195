import React from 'react'
import styled from 'styled-components'
import { useStaticQuery, graphql } from "gatsby"
import Img from "gatsby-image"

const TerminalWrapper = styled.section`
  width: 100%;
  padding: 4rem 2rem;

  ${({theme}) => theme.media.tablet} {
    padding: 6rem 2rem;
  }
`;

const InnerWrapper = styled.div`
  width: 100%;

  ${({theme}) => theme.media.tablet} {
    display: grid;
    grid-template-columns: 55% 1fr;
  }
  ${({theme}) => theme.media.laptop} {
    max-width: 1000px;
    margin: 0 auto;
  }
  ${({theme}) => theme.media.desktop} {
    max-width: 1200px;
    margin: 0 auto;
  }
`;

const BoxText = styled.div`
  position: relative;
  width: 100%;
  z-index: 1;

  ${({theme}) => theme.media.tabletXL} {
    padding: 2rem;
  }

  ::before {
    position: absolute;
    content: '';
    top: -2rem;
    left: -2rem;
    height: calc(100% + 4rem);
    width: 40%;
    z-index: -1;
    background: rgba(70,186,232, .2);

    ${({theme}) => theme.media.laptop} {
      left: -9rem;
      width: 55%;
      height: calc(100% + 0.1rem);
    }
    ${({theme}) => theme.media.laptopXL} {
      left: -14rem;
      width: 70%;
    }
    ${({theme}) => theme.media.desktop} {
      left: -15rem;
      width: 70%;
      height: calc(100% + 2rem);
    }
  }
`;

const H3 = styled.h3`
  font-size: calc(2.27273vw + 14.72727px);
  margin-bottom: 1rem;
  line-height: 1.2;
  font-weight: 700;
  color: ${({theme}) => theme.colors.mainBlack};

  ${({theme}) => theme.media.laptop} {
    font-size: 1.9rem;
  }

  ${({theme}) => theme.media.desktop} {
    font-size: 2.5rem;
  }

  span {
    color: ${({theme}) => theme.colors.mainYellow};
  }
`;

const Text = styled.p`
  margin: 1rem 0;
  color: ${({theme}) => theme.colors.mainBlack};

  ${({theme}) => theme.media.laptopXL} {
    padding: 0 4rem 0 0;
  }

  ${({theme}) => theme.media.desktop} {
    font-size: 1.4rem;
  }
`;

const SmallText = styled.p`
  font-size: .7rem;
  font-weight: 300;

  ${({theme}) => theme.media.desktop} {
    font-size: .8rem;
  }
`;

const BoxImage = styled.div`
  width: 70%;
  margin: 0 auto;
  padding: 1rem 0;

  ${({theme}) => theme.media.tabletXL} {
    
  }

`;


const Terminal = () => {
  const data = useStaticQuery(graphql`
    query {
      terminalImg: file(relativePath: { eq: "terminal-transparent2.png" }) {
        childImageSharp {
          fluid(maxWidth: 500) {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
  `)

  return (
    <TerminalWrapper>
      <InnerWrapper>

        <BoxText>
          <H3 data-sal="fade" data-sal="slide-up" data-sal-easing="ease" data-sal-duration="900">Komputer <span>MINI</span> do usługi serwera zdalnego za darmo*</H3>
          <Text data-sal="fade" data-sal="slide-up" data-sal-easing="ease" data-sal-duration="1000">Zapewniamy Ci pełną administrację, a odpowiedzialność za usługę leży po naszej stronie. Jeśli sprzęt ulegnie uszkodzeniu - nie musisz się martwić, niezwłocznie wymieniamy sprzęt na sprawny. To wszystko jest na naszej głowie!</Text>
          <Text data-sal="fade" data-sal="slide-up" data-sal-easing="ease" data-sal-duration="1100">Do usługi serwera zdalnego opcjonalnie jeśli tego potrzebujesz, dorzucamy GRATIS* MINI komputer - "terminal" do połączenia się z Twoim komputerem w chmurze.</Text>
          <SmallText ata-sal="fade" data-sal="slide-up" data-sal-easing="ease" data-sal-duration="1100">* przy mnimum 3 stanowiskach</SmallText>
        </BoxText>

        <BoxImage>
          <Img fluid={data.terminalImg.childImageSharp.fluid} alt="mini komputer - terminal WYSE"/>
        </BoxImage>

      </InnerWrapper>
    </TerminalWrapper>
  )
}

export default Terminal