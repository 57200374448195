import React, { useRef, useEffect } from "react"
import gsap from 'gsap';
// import { Link } from "gatsby"
import styled from 'styled-components'
import AniLink from "gatsby-plugin-transition-link/AniLink";
import { trackCustomEvent } from 'gatsby-plugin-google-analytics'

import Image from '../../images/topimage.svg';


const StyledWrapper = styled.header`
  width: 100%;
  min-height: 70vh;
  padding: 0 0 1rem 0;
  background: ${({theme}) => theme.colors.mainBlack};

  ${({theme}) => theme.media.laptop} {
    padding: 1rem 5rem;
  }

  ${({theme}) => theme.media.desktopXL} {
    padding: 3rem 5rem;
    min-height: 75vh;
  }

`;

const Container = styled.div`
  width: 100%;
  height: 100%;
  font-weight: 900;
  padding: 4rem 1.5rem 1rem;

  ${({theme}) => theme.media.laptop} {
    display: grid;
    grid-template-columns: 45% 1fr;
  }

  ${({theme}) => theme.media.desktop} {
    max-width: 1500px;
    margin: 0 auto;
  }

  ${({theme}) => theme.media.desktopXL} {
    max-width: 1600px;
  }
`;

const ImageWrapper = styled.div`
  display: none;
  z-index: 2;

  ${({theme}) => theme.media.tablet} {
    display: block;
    opacity: 0;
    width: 85%;
    margin: 0 auto;
  }

  ${({theme}) => theme.media.laptop} {
    justify-content: center;
    align-items: center;
    margin: auto;
    width: 100%;
    display: flex;
    flex-direction: column;
    transform: scale(1.2) translate(20px,60px);
  }

  ${({theme}) => theme.media.laptopXL} {
    transform: scale(1.3) translate(20px,65px);
  }

  ${({theme}) => theme.media.desktop} {
    transform: scale(1.4) translate(25px,55px);
  }
`;

const LeftWrapper = styled.div`
  width: 100%;

  ${({theme}) => theme.media.tablet} {
    width: 80%;
  }

  ${({theme}) => theme.media.laptop} {
    width: 100%;
  }

  ${({theme}) => theme.media.desktop} {
    margin-top: 3rem;
  }
`;

const H1 = styled.h1`
  position: relative;
  color: ${({theme}) => theme.colors.mainWhite};
  padding: 1rem;
  font-size: calc(4.31818vw + 28.18182px);
  line-height: 1.3;

  ${({theme}) => theme.media.laptop} {
    line-height: 1.1;
  }

  ${({theme}) => theme.media.desktop} {
    font-size: 5rem;
  }

  span {
    color: ${({theme}) => theme.colors.mainBlue};
  }

  ::after {
    content: '';
    position: absolute;
    left: 0;
    bottom: 0;
    margin-left: 1rem;
    width: 5rem;
    height: 5px;
    background: ${({theme}) => theme.colors.mainWhite};
  }
`;

const HeaderP = styled.p`
  color: ${({theme}) => theme.colors.mainWhite};
  font-size: calc(.68182vw + 13.81818px);
  line-height: 1.4;
  font-weight: 500;
  padding: 2rem 1rem;

  ${({theme}) => theme.media.laptop} {
    font-size: 1.3rem;
    padding: 2rem 3rem 2rem 1rem;
  }

  ${({theme}) => theme.media.laptopXL} {
    padding: 2rem 5rem 2rem 1rem;
  }

  ${({theme}) => theme.media.desktop} {
    padding: 2rem 10rem 2rem 1rem;
    font-size: 1.3rem;
    // padding-right: 4rem;
  }

`;

const Button = styled.button`
  font-family: ${({theme}) => theme.font.mainFont};
  display: inline-block;
  text-decoration: none;
  padding: .3rem 1rem;
  margin-left: 1rem;
  color: ${({theme}) => theme.colors.mainWhite};
  border: 2px solid #46BAE8;
  letter-spacing: 1px;
  font-weight: 500;
  box-shadow: 3px 3px 0 0 ${({theme}) => theme.colors.mainWhite};

  ${({theme}) => theme.media.laptop} {
    padding: .4rem 1.4rem;
    font-size: 1.1rem;
  }
`;


const Header = () => {

  const imgWrapper = useRef(null);

  useEffect(() => {

    const div = imgWrapper.current;
    const [elements] = imgWrapper.current.children;
  
    const background = elements.getElementById('content-bg')
    const computer = elements.getElementById('computer')
    const cloudBig = elements.getElementById('cloud-big')
    const cloudSmall = elements.getElementById('cloud-small')
    const cloudLeft = elements.getElementById('cloud-left')
    const wave = elements.getElementById('wave')
  
    const backgroundItems = [...background.children]
  
    gsap.set([backgroundItems, cloudBig, cloudLeft, cloudSmall, wave, computer], {autoAlpha: 0});
    gsap.set(div, {opacity: 0})
    gsap.set(wave, {transformOrigin: '50%, 50%', scale: 0.95})
  
    const tl = gsap.timeline();
  
    tl.to(div, {opacity: 1}, 0.3)
      .fromTo(computer, {y: '-=50'}, {duration: .7, y: '+=50', autoAlpha: 1}, 0.2)
      .to(wave, {duration: .5, autoAlpha: 1})
      
      .fromTo(cloudBig, {y: '-=50'}, {duration: .6, y: '+=50', autoAlpha: 1}, '-=.8')
      .fromTo(cloudSmall, {y: '-=50'}, {duration: .6, y: '+=50', autoAlpha: 1}, '-=0.4')
      .fromTo(cloudLeft, {y: '-=50'}, {duration: .6, y: '+=50', autoAlpha: 1}, '-=0.9')
      .to(backgroundItems, {duration: 2, autoAlpha: 1, stagger: 0.1})
  
  }, []);

  return (
    <StyledWrapper>
      <Container>
        <LeftWrapper>
          <H1>
            Komputer w <span>chmurze</span>
          </H1>
          <HeaderP>
            e-Pulpit24 przeniesie Twój komputer do prywatnej chmury internetowej! Home Office i praca zdalna mogą być łatwiejsze i przyjemniejsze.<br></br>
            Pracuj w chmurze już dziś!
          </HeaderP>
          <Button 
            as={AniLink} 
            fade to="/oferta" 
            onClick={e => {
              e.preventDefault()
              trackCustomEvent({
                category: "Header Button",
                action: "Click",
                label: "Przejscie na Abonamenty w Header",
                value: 5
              })
            }}
          >Sprawdź</Button>
        </LeftWrapper>
        <ImageWrapper ref={imgWrapper}>
          <Image/>
        </ImageWrapper>
      </Container>
    </StyledWrapper>
  )
}

export default Header
