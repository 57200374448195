import React from "react"

import SEO from "../components/seo"
import Header from '../components/homeComponents/header'
import HeaderStrip from '../components/homeComponents/headerStrip'
import Assets from '../components/homeComponents/assets'
import Movie from '../components/homeComponents/movie'
import Terminal from '../components/homeComponents/terminal'
import Target from '../components/homeComponents/target'
import Clients from '../components/homeComponents/clients'


const HomePage = () => {

  return (
    <>
      <SEO 
        title="Praca w chmurze dostępna od już! Pracuj zdalnie i wygodnie!" 
        description="Pracujesz zdalnie w trybie home office? Nie masz dostępu do aplikacji służbowych? Pracuj w chmurze, a ominą Cię takie problemy! Komputer w chmurze już od 59zł!"
      />

      <Header />
      <HeaderStrip />
      <Assets />
      <Movie />
      <Terminal />
      <Target />
      <Clients />

    </>
  )
}

export default HomePage

